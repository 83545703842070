"use client";

import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";

import { cn } from "@/lib/utils";
import { Typography } from "@/components/ui/typography";
import Link, { type LinkProps } from "@/atoms/Link";
import Image, { NextImageData } from "@/atoms/Image";
import Color, { isThemeColor, ThemeColor } from "@/types/color";
import ButtonCalendly from "@/molecules/ButtonCalendly";

export interface MagicCardProps extends React.HTMLAttributes<HTMLDivElement> {
  gradientSize?: number;
  gradientColor?: Color;
  gradientOpacity?: number;

  title?: string;
  description?: string;
  descriptionClass?: string;
  link?: LinkProps & {
    text: string;
  };
  image?: NextImageData;
  icon?: ReactNode;
  container?: any;
  iconReverse?: boolean;
}

export function MagicCard({
  children,
  className,
  gradientSize = 200,
  gradientColor = ThemeColor.MUTED,
  gradientOpacity = 0.3,
  title,
  description,
  container,
  link,
  image,
  icon,
  iconReverse,
  descriptionClass,
}: MagicCardProps) {
  const mouseX = useMotionValue(-gradientSize);
  const mouseY = useMotionValue(-gradientSize);
  const motionRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { left, top } = e.currentTarget.getBoundingClientRect();
      mouseX.set(e.clientX - left);
      mouseY.set(e.clientY - top);
    },
    [mouseX, mouseY],
  );

  const handleMouseLeave = useCallback(() => {
    mouseX.set(-gradientSize);
    mouseY.set(-gradientSize);
  }, [mouseX, mouseY, gradientSize]);

  useEffect(() => {
    mouseX.set(-gradientSize);
    mouseY.set(-gradientSize);
  }, [mouseX, mouseY, gradientSize]);

  const gradient = isThemeColor(gradientColor)
    ? `hsl(var(--${gradientColor}))`
    : gradientColor;

  return (
    <div
      {...container}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      className={cn(
        "p-4",
        //"bg-neutral-100 rounded-xl dark:bg-neutral-900 border text-black dark:text-white"
        className,
      )}
    >
      {children ? (
        children
      ) : (
        <>
          <div className="flex flex-col max-md:gap-4 gap-10">
            <div
              className={cn("flex gap-4 items-center", {
                "flex-row-reverse justify-between": iconReverse,
              })}
            >
              {image ? (
                <Image
                  width={200}
                  height={120}
                  relative
                  src={image.src}
                  alt={image.alt || ""}
                />
              ) : null}
              {icon ? icon : null}
              <Typography delay={500} variant={"h3"} className="text-nowrap">
                {title}
              </Typography>
            </div>
            <Typography
              variant={"muted"}
              className={cn("p-4", descriptionClass)}
            >
              {description}
            </Typography>
          </div>
          {link && (
            <div className="w-full flex justify-end mt-10">
              <ButtonCalendly
                onMouseEnter={() => {
                  if (!motionRef.current) return;
                  motionRef.current.classList.add("!opacity-0");
                }}
                onMouseLeave={() => {
                  if (!motionRef.current) return;
                  motionRef.current.classList.remove("!opacity-0");
                }}
                className="relative z-20 hover:bg-card"
              >
                <Typography
                  className="relative z-30"
                  target={link.target}
                  right
                >
                  {link.text || ""}
                </Typography>
              </ButtonCalendly>
            </div>
          )}
        </>
      )}
      <motion.div
        ref={motionRef}
        className="pointer-events-none absolute -inset-px z-0 rounded-xl opacity-0 transition-all duration-300 group-hover:opacity-100"
        style={{
          background: useMotionTemplate`
            radial-gradient(${gradientSize}px circle at ${mouseX}px ${mouseY}px, ${gradient}, transparent 100%)
          `,
          opacity: gradientOpacity,
        }}
      />
    </div>
  );
}
