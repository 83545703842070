"use client";
import React, { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "@/lib/utils";

export const TextGenerateEffect = ({
  words,
  className,
  filter = true,
  duration = 0.5,
}: {
  words: string | React.ReactNode;
  className?: string;
  filter?: boolean;
  duration?: number;
}) => {
  const [scope, animate] = useAnimate();

  const splittedWords =
    typeof words === "string"
      ? words.split(" ")
      : React.Children.toArray(words);

  const wordsArray = splittedWords.reduce(
    (acc: string[] | React.ReactNode[], word) => {
      if (typeof word === "string") {
        return [...acc, ...word.split(" ")];
      }
      return [...acc, word];
    },
    [],
  );

  useEffect(() => {
    animate(
      "span",
      {
        opacity: 1,
        filter: filter ? "blur(0px)" : "none",
      },
      {
        duration: duration ? duration : 1,
        delay: stagger(0.2),
      },
    );
  }, [scope.current]);

  const renderWords = () => {
    return (
      <motion.span ref={scope}>
        {wordsArray.map((word, idx) => {
          return (
            <motion.span
              key={`TextGenerateEffect-motion.span${idx}`}
              className="opacity-0"
              style={{
                filter: filter ? "blur(10px)" : "none",
              }}
            >
              {word}{" "}
            </motion.span>
          );
        })}
      </motion.span>
    );
  };

  return (
    <span className={cn("font-bold", className)}>
      <span className="mt-4">
        <span>{renderWords()}</span>
      </span>
    </span>
  );
};
