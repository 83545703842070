"use client";
import React, { useEffect, useRef } from "react";
import { useInView, useMotionValueEvent, useScroll } from "framer-motion";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import Image from "next/image";
import { SectionLayout } from "@/features/landing/SectionLayout";
import { Typography } from "./typography";

export type StickyScrollProps = {
  content: {
    backgroundColor?: string;
    title: string;
    description: React.ReactNode | string;
    content?: React.ReactNode;
  }[];
  contentClassName?: string;
  showProgressBar?: boolean;
};

export const StickyScroll = ({
  content,
  contentClassName,
  showProgressBar,
}: StickyScrollProps) => {
  const [activeCard, setActiveCard] = React.useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    // uncomment line 22 and comment line 23 if you DONT want the overflow container and want to have it change on the entire page scroll
    // target: ref
    container: ref,
    offset: ["start start", "end start"],
  });

  const cardLength = content.length;
  const isInView = useInView(ref, { amount: 0.5, once: false });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const cardsBreakpoints = content.map((_, index) => index / cardLength);
    const closestBreakpointIndex = cardsBreakpoints.reduce(
      (acc, breakpoint, index) => {
        const distance = Math.abs(latest - breakpoint);
        if (distance < Math.abs(latest - cardsBreakpoints[acc])) {
          return index;
        }
        return acc;
      },
      0,
    );
    setActiveCard(closestBreakpointIndex);
  });

  useEffect(() => {
    if (isInView && ref.current) {
      const { offsetTop, offsetHeight } = ref.current
        .offsetParent as HTMLElement;
      const height = window.innerHeight;
      const top = offsetTop - (height - offsetHeight) / 2;

      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  }, [isInView]);

  return (
    <motion.div
      animate={{
        backgroundColor: content[activeCard]?.backgroundColor,
      }}
      className={cn(
        "no-scrollbar",
        "relative flex max-h-[75dvh] w-full justify-center space-x-10",
        "p-10 py-24",
        {
          "overflow-y-auto": isInView,
          "overflow-y-hidden": !isInView,
        },
        "snap-y snap-mandatory snap-center snap-always scroll-smooth",
      )}
      ref={ref}
    >
      {showProgressBar && (
        <div
          key={"StickyScrollBar"}
          style={{ zIndex: 1000 }}
          className="fixed left-0 top-0  h-[50px] w-full bg-slate-50 shadow-md"
        >
          <div
            style={{
              left: `calc(${((activeCard + 1) / content.length) * 100}% - 100%)`,
            }}
            className={cn(
              "progress-bar sticky left-0 top-0 z-[1000] flex h-[50px] items-center justify-between overflow-hidden bg-accent text-red-500 shadow-md transition-all duration-300",
            )}
          >
            <div>{"Step 1"}</div>
            <div>{"Step 2"}</div>
            <div>{"Step 3"}</div>
            <div>{"Step 4"}</div>
          </div>
        </div>
      )}
      <div className="div relative z-30 flex items-start justify-center px-4 md:flex-1">
        <div className="-mt-10 md:py-20">
          {content.map((item, index) => (
            <div key={item.title + index} className="mt-10 md:py-20">
              <motion.div
                initial={{
                  opacity: 0.5,
                }}
                animate={{
                  opacity: activeCard === index ? 1 : 0.5,
                }}
                className="text-2xl font-bold text-slate-100 max-md:pt-10"
              >
                <Typography variant={"h3"}>{item.title}</Typography>
              </motion.div>

              <motion.div
                initial={{
                  opacity: 0.5,
                }}
                animate={{
                  opacity: activeCard === index ? 1 : 0.5,
                }}
                className={cn(
                  "text-kg md:mb-20 md:my-10 max-w-sm text-slate-300",
                )}
              >
                {item.description}
              </motion.div>
            </div>
          ))}
          <div className="mb-10 h-80" />
        </div>
      </div>
      <div
        className={cn(
          "block md:flex-1  sticky top-0 bottom-0 overflow-hidden",
          "max-md:!fixed max-md:!m-0 max-md:size-full max-md:opacity-30",
          "z-0 md:z-30",
          contentClassName,
        )}
      >
        <div className="relative flex size-full">
          {content.map((item, index) => (
            <motion.div
              // style={{ x }}
              initial={{ scale: 0, opacity: 0.5 }}
              animate={{
                scale: activeCard === index ? 1 : 0,
                opacity: activeCard === index ? 1 : 0.5,
              }}
              key={`StikyScrollContent-${index}`}
              className={cn(
                "absolute flex size-full items-start justify-center md:items-center ",
              )}
            >
              {item.content}
            </motion.div>
          ))}
        </div>
        {/* {content[activeCard].content && (
          
        )} */}
      </div>
    </motion.div>
  );
};


export function StickyScrollRevealDemo() {
  const content = [
    {
      title: "Collaborative Editing",
      // backgroundColor: "hsl(var(--destructive))",
      description:
        "Work together in real time with your team, clients, and stakeholders. Collaborate on documents, share ideas, and make decisions quickly. With our platform, you can streamline your workflow and increase productivity.",
      content: (
        <div className="flex size-full items-start justify-center text-white md:items-center">
          Collaborative Editing
        </div>
      ),
    },
    {
      title: "Real time changes",
      description:
        "See changes as they happen. With our platform, you can track every modification in real time. No more confusion about the latest version of your project. Say goodbye to the chaos of version control and embrace the simplicity of real-time updates.",
      content: (
        <Image
          src="/images/icon.webp"
          width={300}
          height={300}
          className="size-full object-cover"
          alt="linear board demo"
        />
      ),
    },
    {
      title: "Version control",
      // backgroundColor: "hsl(var(--destructive))",
      description:
        "Experience real-time updates and never stress about version control again. Our platform ensures that you're always working on the most recent version of your project, eliminating the need for constant manual updates. Stay in the loop, keep your team aligned, and maintain the flow of your work without any interruptions.",
      content: (
        <Image
          src="/images/icon.webp"
          width={300}
          height={300}
          className="size-full object-cover"
          alt="linear board demo"
        />
      ),
    },
    {
      title: "Running out of content",
      description:
        "Experience real-time updates and never stress about version control again. Our platform ensures that you're always working on the most recent version of your project, eliminating the need for constant manual updates. Stay in the loop, keep your team aligned, and maintain the flow of your work without any interruptions.",
      content: (
        <div className="flex size-full items-center justify-center ">
          Running out of content
        </div>
      ),
    },
  ];
  return (
    <SectionLayout
      // size="full"
      // className={cn(" snap-y snap-center snap-mandatory scroll-smooth")}
      // containerClass="!px-0"
      variant="invert"
    >
      <StickyScroll content={content} />
    </SectionLayout>
  );
}
