export enum ThemeColor {
  BACKGROUND = "background",
  FOREGROUND = "foreground",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ACCENT = "accent",
  DESTRUCTIVE = "destructive",
  MUTED = "muted",
}

export type Color = ThemeColor | string;

export const isThemeColor = (color: Color): color is ThemeColor =>
  Object.values(ThemeColor).includes(color as ThemeColor);

export default Color;
